/**
 *
 * @param {*} order
 * @returns {{statusText:string, timelineStatus?:number}}
 */
const getOrderStatus = (order) => {
  // console.log("order", order);

  if (!order || !order.OrderStatus || order.OrderStatus.length < 1) {
    return { statusText: "" };
  }

  const {
    StatusNo
    // DeliveryDateTo,
    // DeliveryTimeEnd,
    // PlanedDateFrom,
    // PlanedTimeStart
    // , DeliveryDateFrom,
    // DeliveryTimeStart,
    // PlanedDateTo,
    // PlanedTimeEnd
  } = order?.OrderStatus?.[0] || {};

  /*
    PlanedDateFrom: "2022-06-11T00:00:00"
    PlanedTimeStart: "08:00:00",
    // HH:mm:ss

    PlanedDateTo: "2022-06-11T00:00:00"
    PlanedTimeEnd: "16:00:00"

    DeliveryDateFrom: "2022-06-11T00:00:00"
    DeliveryTimeStart: "08:00:00"

    DeliveryDateTo: "2022-06-11T00:00:00"
    DeliveryTimeEnd: "16:00:00"
  */

  // let orderStart = getDateWithAddedTime(PlanedDateFrom, PlanedTimeStart);
  // let orderEnd = getDateWithAddedTime(DeliveryDateTo, DeliveryTimeEnd);

  // orderStart = new Date(orderStart).getTime();
  // orderEnd = new Date(orderEnd).getTime();
  const statusString = StatusNo?.toString();
  const statusNoPos1 = parseInt(statusString[0]);
  const statusNoPos2 = parseInt(statusString[1]);
  const statusNoPos3 = parseInt(statusString[2]);

  // timelineStatus
  // 1 = upcoming orders
  // 2 = ongoing orders
  // 3 = completed orders
  // console.log("statusNoPos1", statusNoPos1);
  // console.log("statusNoPos2", statusNoPos2);
  // console.log("statusNoPos3", statusNoPos3);

  // registered order
  if (statusNoPos1 === 1) {
    return { statusText: "Upcoming", timelineStatus: 1 };
  }

  // upcoming order & on time
  if (statusNoPos1 === 2 && statusNoPos2 === 1 && statusNoPos3 === 1) {
    return { statusText: "On time", timelineStatus: 1 };
  }
  // upcoming order & changed
  if (statusNoPos1 === 2 && statusNoPos2 === 2) {
    return { statusText: "Changed", timelineStatus: 2 };
  }
  // upcoming order & delayed
  if (statusNoPos1 === 2 && statusNoPos2 === 3) {
    return { statusText: "Delayed", timelineStatus: 2 };
  }
  // upcoming order default
  if (statusNoPos1 === 2) {
    return { statusText: "Upcoming", timelineStatus: 1 };
  }

  // ongoing order & on time

  if (statusNoPos1 === 3 && statusNoPos2 === 1 && statusNoPos3 < 3) {
    return { statusText: "On time", timelineStatus: 2 };
  }
  // ongoing order & on time & At Client
  if (statusNoPos1 === 3 && statusNoPos2 === 1 && statusNoPos3 === 3) {
    return { statusText: "At client", timelineStatus: 2 };
  }
  // ongoing and on changed
  if (statusNoPos1 === 3 && statusNoPos2 === 2) {
    return { statusText: "Changed", timelineStatus: 2 };
  }
  // ongoing and on delayed
  if (statusNoPos1 === 3 && statusNoPos2 === 3) {
    return { statusText: "Delayed", timelineStatus: 2 };
  }
  if (statusNoPos1 === 3) {
    return { statusText: "On time", timelineStatus: 2 };
  }
  // finished order
  if (statusNoPos1 > 5) {
    return { statusText: "Invoiced", timelineStatus: 3 };
  }
  // finished order
  if (statusNoPos1 >= 4) {
    return { statusText: "Completed", timelineStatus: 3 };
  }

  return { statusText: "Upcoming", timelineStatus: 3 };
};

export default getOrderStatus;
