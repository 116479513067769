import React, { useState } from "react";
import StatusBar from "../StatusBar/StatusBar";
import styles from "./NotificationDropdown.module.scss";
import { useNavigate } from "react-router-dom";
import { useApi } from "../../context/context";
import { cipDateFormat, getOrderStatus } from "../../utils";

const NotificationDropdown = (props) => {
  const {
    notificationsList,
    // showStatusBar = true,
    // statusText = "",
    setAllAsSeens,
    markAsSeen
  } = props;

  const { getProjectByProjectId, getOrderByOrderId } = useApi();

  const [showAll, setShowAll] = useState(false);
  const totalLength = notificationsList.length;
  const navigate = useNavigate();

  const handleSeeAll = () => {
    setShowAll(true);
  };

  return (
    <div className={styles.notificationsDropdown}>
      <div className={styles.header}>
        <button onClick={setAllAsSeens}>Mark all as seen</button>
      </div>
      {notificationsList?.slice(0, showAll ? totalLength : 5).map((item) => {
        const project = getProjectByProjectId(item.ProjectID);
        const order = getOrderByOrderId(item.OrderID);
        const status = getOrderStatus(order);
        // console.log("item", item);
        return (
          <div
            key={item.NotificationID + item.Message}
            className={`${styles.notification} ${
              item.status ? styles[status?.statusText] : ""
            }`}
            onClick={() => {
              navigate("/project/" + item.ProjectID);
              markAsSeen(item);
            }}
          >
            <div className={styles.spec}>
              <span>
                {cipDateFormat(item.sourceCreated, false, false, true)}
              </span>
              <p>{item.Message}</p>
              <span>
                {project?.Header || item.ProjectID} |{" "}
                {order?.Header || item.OrderID}
              </span>
            </div>
            {order !== "" &&
              status?.statusText &&
              (status?.statusText === "Delayed" ||
                status?.statusText === "Changed") && (
                <div>
                  <StatusBar
                    showStatus={true}
                    statusText={status?.statusText}
                    notificationDropdown={true}
                  />
                </div>
              )}
          </div>
        );
      })}
      {notificationsList < 1 && (
        <p>
          <br />
          No new notifications.
          <br />
        </p>
      )}
      {notificationsList.length > 5 && !showAll && (
        <a onClick={handleSeeAll} href="#show-all-notifications">
          See all ({totalLength - 5} more){" "}
        </a>
      )}
    </div>
  );
};

export default NotificationDropdown;
