import { useTranslation } from "react-i18next";

import { Link, useNavigate, useLocation } from "react-router-dom";

import logo from "../../assets/images/mtab-logo.svg";
import { User } from "../../assets/icons";

import { ApiNotifications } from "../";
import styles from "./Header.module.scss";
import { useApi } from "context/context";

const Header = () => {
  const { isVerified, user } = useApi();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  // TODO: revisit this logic (it's the logic to create hamburger menu on mobile)
  const toggleMenu = () => {
    let menu = document.querySelector("#tool-menu");
    let spans = menu.querySelectorAll("button > span");

    if (menu.classList.contains(`${styles.open}`)) {
      menu.classList.remove(`${styles.open}`);
    } else {
      menu.classList.add(`${styles.open}`);
    }
    for (let i = 0; i < spans?.length; i++) {
      if (spans[i].classList.contains(`${styles.change}`)) {
        spans[i].classList.remove(`${styles.change}`);
      } else {
        spans[i].classList.add(`${styles.change}`);
      }
    }
  };

  return (
    <header>
      <a href="/">
        <img src={logo} className={styles.logo} alt="logo" />
      </a>
      <nav>
        {isVerified && (
          <ul className={styles.mainMenu}>
            <li>
              <Link
                to={"/"}
                className={
                  !location?.pathname?.includes("/invoices")
                    ? styles.active
                    : styles.notActive
                }
              >
                {t("projects")}
              </Link>
            </li>
            <li>
              <Link
                to={"/invoices"}
                className={
                  location?.pathname?.includes("/invoices")
                    ? styles.active
                    : styles.notActive
                }
              >
                {t("invoices")}
              </Link>
            </li>
          </ul>
        )}
        <button className={styles.burger} onClick={toggleMenu}>
          <span></span>
          <span></span>
          <span></span>
        </button>
        <ul className={styles.toolMenu} id="tool-menu">
          <ApiNotifications isUserVerified={isVerified} />
          {/* <Notifications
            notifyList={notifyList}
            status={status}
            isUserVerified={isVerified}
            setNotifications={setNotifications}
          /> */}
          {isVerified && (
            <li onClick={() => navigate("/profile")}>
              <User className={styles.user} />
              {user?.FirstName}
            </li>
          )}
          {/* TODO: Temporarily hiding the language */}
          {/* <li className={styles.language}>
            {i18n.language}
            <Arrow className={styles.arrow} />
            <LanguageDropdown changeLanguage={i18n.changeLanguage} />
          </li> */}
        </ul>
      </nav>
    </header>
  );
};

export default Header;
